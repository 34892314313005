<template lang="pug">
Modal(:show="show",  :onClose="close")
  .header-container
      h2 {{ $t('.title') }}
      p {{ $t('.message') }}

  .buttons-container
    button.button-primary
      | {{ $t('.buttons.confirm') }}

</template>
<script>
import Modal from '../../../components/modal.vue'

export default {
  components: { Modal },

  props: {
    show: { type: Boolean, default: false }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "staticPages.components.partnershipTermModal",
    }
  },

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
  h2 {
    font-size: 1.7rem;
    font-weight: 550;
    color: $second-color-dark;
  }

  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10rem;
  }
</style>
