<template lang="pug">
#page.transfer-page
  navbar(currentPage="adminManagementIndex")

  .transfer-container
    breadcrumbs(:items="breadcrumbItems")

    .buttons-container
      button.destroy-transfer(
        v-if="!transfer.billId",
        :disabled="!transfer.editable",
        @click="showDeleteTransferModal = true"
      )
        | {{ $t('.actions.buttons.destroy') }}

      button.cancel-transfer(
        v-else-if="transfer.status != 'canceled'",
        :disabled="!transfer.editable",
        @click="showCancelTransferModal = true"
      )
        | {{ $t('.actions.buttons.cancel') }}

      button.view-bill(
        :disabled="!transfer.billId",
        @click="redirectToBillPage"
      )
        | {{ $t('.actions.buttons.bill') }}

      button.download-transfer(
        @click="downloadTransferReport"
      )
        | {{ $t('.actions.buttons.download') }}

    .transfer-show-container
      expansible-card(:title="$t('.titles.overview')", :expanded="true")
        .inline-section
          .four-columns
            h3.info-title {{ $t('.overview.dueDate') }}
            editable-date-field(
              v-if="transferLoaded",
              name="transfer[due_date]",
              :value="transfer.dueDate",
              :callback="reloadValues",
              :path="transferPath"
            )
          .four-columns
            h3.info-title {{ $t('.overview.liquidationDate') }}
            editable-date-field(
              v-if="transferLoaded",
              name="transfer[liquidation_date]",
              :value="transfer.liquidationDate",
              :callback="reloadValues",
              :path="transferPath"
            )
          .four-columns
            h3.info-title {{ $t('.overview.totalValue') }}
            p.info-content
              | {{ `R$ ${formatValue(transfer.value)}` || '-' }}
          .four-columns
            h3.info-title {{ $t('.overview.contractCode') }}
            p.info-content
              | {{ transfer.contractCode || '-' }}

        .inline-section
          .four-columns
            h3.info-title {{ $t('.overview.agency') }}
            p.info-content
              | {{ transfer.realEstateAgencyName || '-' }}
          .four-columns
            h3.info-title {{ $t('.overview.status') }}
            .flag(
              v-show="!editingStatus",
              :class="transfer.status",
              @click="editStatus"
            )
              | {{ $t(`.overview.${transfer.status}`) }}
            editable-select-field(
              ref="editableSelectField",
              v-if="transferLoaded",
              v-show="editingStatus",
              name="transfer[status]",
              :value="transfer.status",
              :options="statusOptions",
              :callback="reloadValues",
              :path="transferPath"
            )
          .four-columns
            h3.info-title {{ $t('.overview.tenantName') }}
            p.info-content
              | {{ transfer.tenantName || '-' }}
          .four-columns
            h3.info-title {{ $t('.overview.propertyAddress') }}
            p.info-content
              | {{ transfer.contractAddress || '-' }}

        .inline-section
          .four-columns
            h3.info-title {{ $t('.overview.transferTo.title') }}
            p.info-content
              | {{ $t(`.overview.transferTo.${transfer.contractTransferTo}`) }}

    .transfer-item-index-container
      expansible-card(:title="$t('.titles.details')", :expanded="true")
        .buttons-container
          button.button-primary(
            :disabled="!transfer.editable",
            @click="showCreateTransferItemModal = true"
          )
            | {{ $t('.actions.buttons.newItem') }}
        .table-section
          .table-container
            table
              thead
                tr
                  th(v-for="name in columnNames")
                    p {{ $t(`.details.table.${name}`) }}

              tbody
                tr(v-for="transferItem in transferItems")
                  td.transfer-item
                    | {{ transferItem.description }}
                  td.transfer-item-value
                    | {{ `R$ ${formatValue(transferItem.value)}` }}
                  td.transfer-item-complement
                    | {{ transferItem.complement }}
                  td.actions
                    button.button-blue(
                      :disabled="!transfer.editable",
                      @click="openActionsModal(transferItem, 'edit')",
                    )
                      i.fa.fa-edit
                    button.button-red(
                      :disabled="!transfer.editable",
                      @click="openActionsModal(transferItem, 'delete')"
                    )
                      i.fa.fa-trash

              tfoot
                td {{ $t('.details.table.total') }}
                td {{ `R$ ${formatValue(transfer.value)}` }}
                td
                td

  delete-modal(
    :show="showDeleteTransferModal",
    :callback="deleteTransfer",
    @close="showDeleteTransferModal = false"
  )
  cancel-modal(
    :show="showCancelTransferModal",
    :callback="reloadValues",
    :transferId="transfer.id",
    @close="showCancelTransferModal = false"
  )
  edit-transfer-item-modal(
    :show="showEditTransferItemModal",
    :transferItem="transferItem",
    :transferId="transfer.id",
    :callback="reloadTransferItems",
    @close="showEditTransferItemModal = false"
  )
  delete-transfer-item-modal(
    :show="showDeleteTransferItemModal",
    :callback="deleteTransferItem",
    @close="showDeleteTransferItemModal = false"
  )
  create-transfer-item-modal(
    :show="showCreateTransferItemModal",
    :transferId="transfer.id",
    :callback="reloadTransferItems",
    @close="showCreateTransferItemModal = false"
  )
</template>
<script>
import ExpansibleCard from "../../../components/expansible-card.vue"
import CheckboxField from '../../../../../../components/desktop/checkbox-field.vue'
import EditTransferItemModal from '../../../components/transfers_items/edit-modal.vue'
import DeleteModal from '../../../components/transfers/delete-modal.vue'
import CancelModal from '../../../components/transfers/cancel-modal.vue'
import DeleteTransferItemModal from '../../../components/transfers_items/delete-modal.vue'
import CreateTransferItemModal from '../../../components/transfers_items/create-modal.vue'
import Overview from '../../../components/overview.vue'
import Navbar from "../../../components/navbar.vue"
import Breadcrumbs from '../../../../components/breadcrumbs.vue'
import EditableDateField from "../../../../../../components/desktop/editable-fields/editable-date-field.vue"
import EditableSelectField from "../../../../../../components/desktop/editable-fields/editable-select-field.vue"

import objects from "lib/objects"

export default {
  components: {
    ExpansibleCard, CheckboxField, EditTransferItemModal, Overview, Navbar,
    Breadcrumbs, DeleteTransferItemModal, CreateTransferItemModal,
    DeleteModal, CancelModal, EditableDateField, EditableSelectField
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.transfers.show",

      // tool
      routePath: "administracao/gerenciamento/repasses",
      transferStatus: "",

      transfer: {},
      transferItems: [],
      editingStatus: false,
      transferLoaded: false,

      // modal
      showDeleteTransferModal: false,
      showCancelTransferModal: false,
      showEditTransferItemModal: false,
      showDeleteTransferItemModal: false,
      showCreateTransferItemModal: false,
      transferItem: {}
    }
  },

  methods: {
    init(transfer) {
      this.transfer = objects.camelize(transfer)
      this.transferLoaded = true
    },

    initTransferItems(transferItems) {
      this.transferItems.push(...objects.camelize(transferItems))
    },

    redirectToBillPage() {
      this.$router.push({
        path: `/administracao/gerenciamento/cobrancas/${this.transfer.billId}`
      })
    },

    openActionsModal(transferItem, modal) {
      this.transferItem = transferItem

      if (modal == 'edit' ) {
         this.showEditTransferItemModal = true

         return
      }

      this.showDeleteTransferItemModal = true
    },

    reloadValues() {
      this.editingStatus = false
      this.transferItems = []

      this.fetch()
    },

    fetch() {
      this.$http
        .get(`${this.transferPath}.json`)
        .then((response) => {
          const transfer = response.data
          this.transferLoaded = false

          this.init(transfer)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.fetchItems()
        })
    },

    fetchItems() {
      this.$http
        .get(`${this.transferPath}/transfer-items.json`)
        .then((response) => {
          const transferItems = response.data

          this.initTransferItems(transferItems)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetchItems.failure"))
        })
    },

    downloadTransferReport() {
      this.$http.get(`${this.transferPath}/download`)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })

          window.open(window.URL.createObjectURL(blob))
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.download.failure"))
        })
    },

    reloadTransferItems() {
      this.transferItems = []
      this.fetch()
    },

    deleteTransferItem() {
      this.$http
        .delete(`${this.transferPath}/transfer-items/${this.transferItem.id}`)
        .then(() => {
          this.errors = {}
          this.$notifications.info(this.$t(".notifications.deleteTransferItem.success"))

          this.reloadTransferItems()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.deleteTransferItem.failure"))
        })
        .finally(() => {
          this.showDeleteTransferItemModal = false
        })
    },

    deleteTransfer() {
      this.$http
        .delete(this.transferPath)
        .then(() => {
          this.errors = {}

          this.$notifications.info(this.$t(".notifications.deleteTransfer.success"))
          this.$router.replace({ path:"/administracao/gerenciamento", hash: "#transfers" })
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.deleteTransfer.failure"))
        })
        .finally(() => {
          this.showDeleteTransferModal = false
        })
    },

    formatValue(value) {
      if (value !== null) {
        return value.toString().replace('.', ',')
      }

      return ''
    },

    editStatus() {
      this.editingStatus = true
      this.$refs.editableSelectField.editingDisabled = false
    },

    parseRoute() { }
  },

  computed: {
    statusOptions() {
      const statuses = ['waiting', 'sent_to_finance', 'liquidated', 'canceled']

      return statuses.map(status => {
        return {
          id: status,
          text: this.$t(`.overview.${status}`)
        }
      })
    },

    getTransferStatusClass() {
      if (this.transfer.sendToFinance) { return 'sent' }

      return 'pending'
    },

    getStatusText() {
      if (this.transfer.liquidationDate) {
        return this.$t('.overview.liquidated')
      }

      if (this.transfer.sendToFinance) {
        return this.$t('.overview.sentToFinance')
      }

      return this.$t('.overview.pendingTransfer')
    },

    columnNames() {
      return ['transferItem', 'transferItemValue', 'complement', 'actions']
    },

    getTransferParam() {
      return this.$route.params.id
    },

    transferPath() {
      return `/admins/transfers/${this.getTransferParam}`
    },

    breadcrumbItems() {
      return [
        {
          name: this.$t('.breadcrumbs.management'),
          url: this.routePath.replace("/repasses", '')
        },
        {
          name: this.$t('.breadcrumbs.transfer'),
          url:  this.routePath.replace("/repasses", ''),
          hash: 'transfers'
        },
        {
          name: `#${this.getTransferParam}`,
          url: `${this.routePath}/${this.getTransferParam}`
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.transfer-container {
  width: 75%;
  height: 100vh;
  padding-top: 2rem;
  padding-bottom: 4rem;
  margin: auto;
}

.inline-section {
  display: flex;
  column-gap: 1.5rem;
  justify-content: space-between;
  word-break: break-word;

  .flag {
    padding: 1rem;

    font-size: 1.3rem;
    font-weight: 500;
    color: $white-color;
    width: fit-content;
    margin-top: 0.5rem;
    border-radius: 0.6rem;

    &.waiting {
      background-color: $yellow-color;

      &:hover {
        background-color: lighten($yellow-color, 2%);
      }
    }

    &.sent_to_finance {
      background-color: $blue-color;

      &:hover {
        background-color: lighten($blue-color, 5%);
      }
    }

    &.liquidated {
      background-color: $primary-color;

      &:hover {
        background-color: lighten($primary-color, 5%);
      }
    }

    &.canceled {
      background-color: $red-color;

      &:hover {
        background-color: lighten($red-color, 2%);
      }
    }
  }

  .one-column {
    width: 10%;
  }

  .two-columns {
    width: 47%;
  }

  .three-columns {
    width: 30%;
  }

  .four-columns {
    width: 52%;
  }

  .full-size {
    width: 100%;
  }
}

.info-title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: $second-color-light;
}

.info-content {
  font-size: 1.5rem;
  font-family: Lato;

  &.sent {
    background-color: $primary-color;
  }

  &.pending {
    background-color: $red-color;
  }
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
}

button {
  font-size: 1.5rem;
  text-transform: none;

  &.destroy-transfer, &.cancel-transfer {
    border: none;
    color: $red-color;
    background-color: lighten($red-color, 54%);

    &:hover {
      background-color: lighten($red-color, 49%);
    }
  }

  &.download-transfer, &.add-new-item, &.view-bill {
    border: none;
    color: $blue-color;
    background-color: lighten($blue-color, 36%);

    &:hover {
      background-color: lighten($blue-color, 31%);
    }
  }

  &.button-red, &.button-blue {
    padding: 0;
    height: 38px;
    width: 38px;
  }
}

.table-container {
  height: auto !important;
  max-height: 40vh;

  table {
    tbody {
      tr {
        height: 5rem !important;
      }
    }

    td {
      text-transform: capitalize !important;
    }

    .transfer-item, .transfer-item-value {
      min-width: 13rem !important;
    }

    .transfer-item-complement {
      width: 100%;
    }

    .actions {
      min-width: fit-content;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;

      button {
        margin: 0;
        width: 3.8rem;
        padding: 0;
      }

      i {
        font-size: 1.8rem;
      }
    }

    .status-container {
      background-color: $primary-color;
      color: $white-color;
      padding: 0.5rem 2rem;
      width: min-content;
      border-radius: 0.5rem;
      text-transform: uppercase;
      font-size: 1.4rem;
    }
  }
}
</style>