<template lang="pug">
.contracts-table
  .table-section
    .table-container(@scroll="event => loadCallback(event)")
      table
        thead
          tr
            th(v-for="name in columnNames")
              p {{ $t(`.table.columns.${name}`) }}

        tbody
          tr(v-for="contract in contracts")
            td.contract-id(@click="openContract(contract.id)")
              | {{ contract.id }}

            td.contract-code(@click="openContract(contract.id)")
              | {{ contract.code }}

            td.tenant-name(@click="openContract(contract.id)")
              | {{ contract.tenantName }}

            td.tenant-cpf(@click="openContract(contract.id)")
              | {{ contract.tenantCpf }}

            td.agency-name(@click="openContract(contract.id)")
              | {{ contract.agencyName }}

            td.status(@click="openContract(contract.id)")
              .status-container(:class="contract.status")
                | {{ $t(`.table.status.${contract.status}`) }}

          tr.loading(v-if="loading")
            td(v-for="cell in columnNames")
              .cell
    footer
      p {{ $t('.pagination.text', { length: contracts.length, total: total }) }}
</template>
<script>
export default {
  props: {
    contracts: { type: Array, required: true },
    total: { type: Number, required: true },
    loading: { type: Boolean, default: false },
    loadCallback: { type: Function, default: () => {} }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.contracts.index",

      // table
      columnNames: [
        "contractId",
        "contractCode",
        "tenantName",
        "tenantCpf",
        "agency",
        "status"
      ]
    }
  },

  methods: {
    openContract(contractId) {
      this.$router.push({ path: `gerenciamento/contratos/${contractId}` })
    }
  }
}
</script>
<style lang="scss" scoped>
  .table-container {
    height: 40vh !important;
  }

  .contracts-index-container {
    p {
        margin: 0;
        font-size: 1.6rem;
    }
  }

  .table-section {
    margin-top: 1.5rem;
  }

  .status-container {
    border-radius: 0.5rem;

    width: min-content;

    color: $white-color;
    text-transform: uppercase;
    font-size: 1.4rem;

    padding: 0.5rem 2rem;

    &.active {
      background-color: $primary-color;
    }

    &.inactive {
      background-color: $grey-color;
    }

    &.paused {
      background-color: $yellow-color;
    }

    &.under_verification {
      background-color: $yellow-color;
    }

    &.preparing_eviction {
      background-color: $yellow-color;
    }

    &.to_be_vacant,
    &.eviction_process {
      background-color: $grey-color;
    }
  }

  table {
    tbody {
      tr {
        .contract-id,
        .status {
          min-width: fit-content !important;
        }

        &:hover {
          td {
            background-color: darken($white-color-dark, 6%);

            &.contract-id,
            &.contract-code,
            &.tenant-name,
            &.tenant-cpf,
            &.agency-name,
            &.status {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>
