<template lang="pug">
.score-reports-index
  expansible-card(:title="$t('.titles.overview')", :expanded="true")
    overview(:items="overviewItems")

  .scoreReports-index-container
    card
      .filtering-container
        h2 {{ $t('.titles.index') }}

        .actions
          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter

      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        date-field(
          v-model="query.period"
          :label="$t('.label.period')"
          :range="true"
        )

        agency-field(
          v-model="query.realEstateAgency"
          name="real_estate_agency"
          :label="$t('.label.realEstateAgency')"
          fetchUrl="/admins"
          path="real-estate/agencies"
          allowClear
        )

        user-field(
          v-model="query.realEstateUser"
          name="real_estate_user"
          :label="$t('.label.realEstateUser')"
          :agencyId="query.realEstateAgency"
          allowClear
        )

        input-field(
          v-model="query.cpf"
          name="cpf"
          :label="$t('.label.cpf')"
          :placeholder="$t('.placeholder.cpf')"
          mask="###.###.###-##"
        )

        input-field(
          v-model="query.email"
          type="email"
          name="email"
          :label="$t('.label.email')"
          :placeholder="$t('.placeholder.email')"
        )

        phone-field(
          v-model="query.phone"
          name="phone"
          :label="$t('.label.phone')"
          :placeholder="$t('.placeholder.phone')"
        )

        input-field(
          v-model="query.zipCode"
          type="text"
          name="zip_code"
          :label="$t('.label.zipCode')"
          :placeholder="$t('.placeholder.zipCode')"
          v-maska="'#####-###'"
        )

      .table-section
        .table-container(@scroll="autoLoad")
          table
            thead
              tr
                th(v-for="name in columnNames")
                  p {{ $t(`.table.columns.${name}`) }}

            tbody
              tr(
                v-for="scoreReport in scoreReports"
                @click="openScoreReport(scoreReport.id)"
              )
                td.main-tenant-cpf
                  span(@click.stop)
                    | {{ scoreReport.mainTenantCpf }}

                td.main-tenant-name
                  span(@click.stop)
                    | {{ scoreReport.mainTenantName || '-' }}

                td.property-type
                  span(@click.stop)
                    | {{ propertyTypeTranslate(scoreReport.propertyType) }}

                td.real-estate-agency
                  span(@click.stop)
                    | {{ scoreReport.realEstateAgencyName }}

                td.created-at
                  span(@click.stop)
                    | {{ $l('date.formats.default', scoreReport.createdAt) }}

                td.status(@click.stop)
                  .status-container(:class="scoreReport.status")
                    | {{ $t(`.table.status.${scoreReport.status}`) }}

                td.approved
                  span(@click.stop)
                    | {{ $t(`.table.approved.${scoreReport.result.approved}`) }}

                td.has-contract
                  span(@click.stop)
                    | {{ hasContractText(scoreReport) }}

                td.fee
                  span(@click.stop)
                    | {{ scoreReport.result.approved ? `${scoreReport.result.value}%` : '-' }}

                td.contract-opening-value
                  span(@click.stop)
                    | {{ valueFormat(scoreReport.result.contractOpeningValue) }}

                td.other-tenants
                  span(@click.stop)
                    | {{ $t(`.table.approved.${scoreReport.scores.length > 1}`) }}

              tr.loading(v-if="loading")
                td(v-for="cell in columnNames")
                  .cell
        footer
          p {{ $t('.pagination.text', { length: scoreReports.length, total: pagination.total }) }}
</template>
<script>
import ExpansibleCard from "../../../components/expansible-card.vue"
import AgencyField from '../../../components/agency-field.vue'
import UserField from '../../components/user-field.vue'
import Overview from '../overview.vue'
import FilterContainer from '../../../components/filter-container.vue'

import objects from "lib/objects"
import currencyFormatter from 'lib/currency-formatter'

export default {
  components: {
    ExpansibleCard, AgencyField, UserField, Overview, FilterContainer
  },

  mounted() {
    this.fetch()
    this.fetchOverview()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.index",

      // filter
      filterOpened: false,

      // table
      scoreReports: [],
      pagination: { total: 0 },
      loading: false,

      // overview
      overviewData: {
        total: { count: 0, percentage: 0 },
        approved: { count: 0, percentage: 0 },
        disapproved: { count: 0, percentage: 0 }
      },

      query: {
        period: (() => {
          const date = new Date()
          const firstDayOfMonth = new Date(
            date.getFullYear(), date.getMonth(), 1
          )
          const lastDayOfMonth = new Date(
            date.getFullYear(), date.getMonth() + 1, 0
          )

          return [firstDayOfMonth, lastDayOfMonth]
        })(),
        realEstateAgency: null,
        realEstateUser: null,
        cpf: null,
        email: null,
        phone: null,
        zipCode: null
      }
    }
  },

  methods: {
    init(scoreReports, pagination) {
      this.scoreReports.push(...objects.camelize(scoreReports))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    filter() {
      this.replaceQuery()

      this.scoreReports = []

      this.fetch()
      this.fetchOverview()
      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get(`/admins/score-reports.json`, { params: this.httpParams(page) })
        .then((response) => {
          const body = response.data
          const scoreReports = body.data
          const pagination = body.pagination || {}

          this.init(scoreReports, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.allPageChecked = false
          this.loading = false
        })
    },

    fetchOverview() {
      this.$http
        .get(`/admins/infos/score-reports.json`, { params: this.httpParams() })
        .then((response) => {
          const overview = response.data

          this.overviewData = overview
        })
        .catch(() => {
          this.overviewData = {
            total: { count: 0, percentage: 0 },
            approved: { count: 0, percentage: 0 },
            disapproved: { count: 0, percentage: 0 }
          }

          this.$notifications.error(
            this.$t(".notifications.fetchOverview.failure")
          )
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({
        created_at: {
          start_date: this.query.period && this.query.period[0],
          end_date: this.query.period && this.query.period[1]
        },
        real_estate_agency: this.query.realEstateAgency,
        real_estate_user: this.query.realEstateUser,
        cpf: this.query.cpf,
        email: this.query.email,
        phone: this.query.phone,
        zipCode: this.query.zipCode,
        page: page
      })
    },

    hasContractText(scoreReport) {
      if (scoreReport.result.approved) {
        return this.$t(`.table.hasContract.${scoreReport.hasContract}`)
      }

      return '-'
    },

    valueFormat(value) {
      return value ? currencyFormatter.format(value) : '-'
    },

    openScoreReport(scoreReportId) {
      const path = `gerenciamento/consultas/${scoreReportId}`
      const route = this.$router.resolve({ path: path })

      window.open(route.href, '_blank')
    },

    propertyTypeTranslate(type) {
      if (type) {
        return this.$t(
          `models.scoreReport.attributes.propertyTypeOptions.${type}`
        )
      }

      return '-'
    }
  },

  computed: {
    overviewItems() {
      return Object.entries(this.overviewData).map(([key, value]) => {
        return {
          description: this.$t(`.overview.${key}`),
          quantity: value.count,
          percentage: value.percentage
        }
      })
    },

    columnNames() {
      return [
        'mainTenantCpf', 'mainTenantName', 'propertyType', 'realEstateAgency',
        'createdAt', 'status', 'approved', 'hasContract', 'fee',
        'contractOpeningValue', 'otherTenants'
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
  form {
    display: grid;
    grid-template-columns: repeat(3, auto) repeat(1, max-content);
    column-gap: 0.5em;

    .button-primary {
      margin-bottom: 1.4rem;
      margin-top: auto;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 5rem;

    p, button {
      margin: 0;
    }
  }

  .table-container {
    height: 40vh !important;
  }

  .name-container {
    display: inline-block;
    vertical-align: top;

    width: 28.2rem;

    overflow-wrap: break-word;
    line-break: loose;
    white-space: normal;
  }

  .filtering-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: $primary-color;
    }

    .actions {
      display: flex;

      button.opened {
        opacity: 0.5;
      }

      button.icon {
        font-size: 1.5rem;
        width: 4rem;
        padding: 0;
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
  }

  .table-section{
    margin-top: 1.5rem;
  }

  table {
    tbody {
      tr {
        min-width: 0 !important;

        &:hover {
          td {
            background-color: darken($white-color-dark, 6%);

            &.main-tenant-cpf,
            &.main-tenant-name,
            &.property-type,
            &.real-estate-agency,
            &.created-at,
            &.status,
            &.approved,
            &.has-contract,
            &.fee,
            &.contract-opening-value,
            &.other-tenants {
              cursor: pointer;
            }
          }
        }
      }
    }

    span {
      padding: 1rem;
    }

    td {
      text-transform: capitalize !important;
      width: fit-content;
    }

    .status-container {
      border-radius: 0.5rem;

      width: min-content;

      color: $white-color;
      text-transform: uppercase;
      font-size: 1.4rem;

      padding: 0.5rem 2rem;

      &.done {
          background-color: $primary-color;
      }

      &.error, &.not_found {
          background-color: $red-color;
      }

      &.todo {
          background-color: $blue-color;
      }

      &.wip {
          background-color: $yellow-color,
      }
    }
  }
</style>
