<doc lang="markdown">
  Autenticação

  Formulário de autenticação, exibido quando necessário pelo VueRouter
</doc>

<template lang="pug">
.tool.login.sheet
  h1 {{ heading ? heading : $t('.heading') }}
  form.login(ref="form", action="/", @submit.prevent="submit")
    .row
      .twelve.columns
        input-field(
          :autofocus="true",
          type="email",
          v-model="email",
          name="email",
          :placeholder="$t('.placeholder.email')"
        )
    .row
      .twelve.columns
        input-field(
          v-model="password",
          name="password",
          type="password",
          :placeholder="$t('.placeholder.password')"
        )
    .row
      .twelve.columns
        button.button-primary.u-full-width(
          type="submit",
          :disabled="authenticating"
        )
          | {{ submitText }}

    .row
      .twelve.columns
        router-link(
          :to="{ name: `${this.role}NewPassword` }",
          data-action="forgot-password"
        )
          | {{ $t('.button.forgotPassword') }}
</template>
<script>
import cryptography from '../../../modules/cryptography'

export default {
  props: {
    redirect: { type: String, required: true }, // default para rota "root"
    role: { type: String },
    url: { type: String },
    heading: { type: String }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "login",

      authenticating: false,

      // form
      password: null,
    }
  },

  computed: {
    submitText() {
      if (this.authenticating) {
        return this.$t(".button.authenticating")
      }

      return this.$t(".button.authenticate")
    },
  },

  methods: {
    submit() {
      const formData = new FormData(this.$refs.form)
      const password = formData.get('password')
      const email = formData.get('email')

      formData.set('password', cryptography.encrypt(password))
      formData.set('email', cryptography.encrypt(email))

      this.authenticating = true
      this.$http
        .post(`${this.url}/sign_in`, formData)
        .then((response) => {
          const { data } = response

          Object.keys(data.data).forEach(key => {
            data.data[key] = cryptography.decrypt(data.data[key])
          })

          this.$notifications.clear()

          app.authToken.accessToken = response.headers[
            app.authToken.accessTokenHEADER
          ]

          app.authToken.client = response.headers[app.authToken.clientHEADER]

          app.authToken.uid = response.headers[app.authToken.uidHEADER]

          app.auth.authenticate(data.data, this.role)

          this.$router.replace({ path: this.redirect })
        })
        .catch((err) => {
          const response = err.response

          if (response == null) {
            return this.$notifications.error(
              this.$t(".notifications.submit.failure")
            )
          }

          const { errors } = response.data

          errors.forEach(element => {
            this.$notifications.error(element)
          })
        })
        .then(() => {
          this.authenticating = false

          this.$refs.form.reset()
        })
    },
  },
}
</script>

<style scoped lang="scss">
.sheet {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 340px;
  font-weight: 300;
  font-size: 16px;
  border-radius: 0.5rem;

  h1 {
    font-size: 20px;
    letter-spacing: 0.3px;
  }
}
</style>