<template lang="pug">
select-field(
  :name="name",
  :options="options",
  :includeBlank="true",
  :error="error",
  :label="$t('.label')"
  @input="(val) => { $emit('update:modelValue', val) }"
  :multiple="true"
)
</template>

<script>
  export default {
    data() {
      return {
        // i18n
        defaultI18nScope: "admin.contracts.statusField"
      }
    },

    computed: {
      options() {
        return [
          'active',
          'inactive',
          'to_be_vacant',
          'eviction_process',
          'paused',
          'under_verification',
          'preparing_eviction'
        ].map(status => (
          { id: status, text: this.translateStatus(status) }
        ))
      }
    },

    methods: {
      translateStatus(status) {
        return this.$t(`models.contract.status.${status}`)
      }
    },

    props: {
      name: { type: String },
      error: { type: String }
    }
  }
</script>
