<template lang="pug">
ErrorPage(
  :title="$t('.title')",
  :subtitle="$t('.subtitle')",
  :message="$t('.message')",
  backgroundImage="401.svg"
)
</template>

<script>
import ErrorPage from "./components/error-page.vue"

export default {
  components: { ErrorPage },

  data() {
    return {
      defaultI18nScope: "staticPages.unauthorized"
    }
  },

  mounted() {
    app.auth.clear()
  }
}
</script>
