<template lang="pug">
.step-card(:class="{ error: hasError }" ref="stepCard")
  .line
    h2.title {{ title }}

  .content
    .infos(v-if="!expanded")
      .info(v-for="{ label, value } in infos?.slice(0, 3)")
        h3.info-title {{ label }}
        p.info-content {{ value || '-' }}

    .expansible(v-show="editable && expanded" ref="expansible")
      slot

    .infos(v-if="!editable && expanded")
      .info(v-for="{ label, value } in infos")
        h3.info-title {{ label }}
        p.info-content {{ value || '-' }}

    .button-container(v-if="editable")
      button.button-primary(
        v-if="!expanded"
        :class="{ 'button-red': hasError }"
        :disabled="disableButton"
        @click.prevent="expand"
      )
        | {{ $t('.buttons.edit') }}

      button.button-primary(
        v-else
        :class="{ 'button-red': hasError }"
        :disabled="disableButton"
        @click.prevent="confirmAction"
      )
        | {{ $t(`.buttons.${sender ? 'submit' : 'next'}`) }}

    .button-container(v-else)
      button.button-primary(
        v-if="!expanded"
        :disabled="disableButton || infos.length <= 3"
        @click.prevent="expand"
      )
        | {{ $t('.buttons.expand') }}

      button.button-primary(
        v-else
        :disabled="disableButton || infos.length <= 3"
        @click.prevent="collapse"
      )
        | {{ $t('.buttons.collapse') }}
</template>

<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.contract.new.stepCard",

      infos: [],

      hasError: false
    }
  },

  props: {
    editable: { type: Boolean, default: false },
    expanded: { type: Boolean, default: false },
    title: { type: String, required: true },
    disableButton: { type: Boolean, default: false },
    errors: { type: Object, default: () => ({}) },
    expand: { type: Function, required: true },
    collapse: { type: Function, required: true },
    confirm: { type: Function, required: true },
    sender: { type: Boolean, default: false }
  },

  mounted() {
    this.updateInfos()
  },

  updated() {
    this.updateInfos()
  },

  watch: {
    expanded() {
      this.updateInfos()
    },

    errors() {
      this.$nextTick(() => {
        this.hasError = !!this.$refs.expansible.querySelector('.error')
      })
    }
  },

  methods: {
    getFormElements() {
      return this.$refs?.expansible?.querySelectorAll('input, select, textarea')
    },

    confirmAction() {
      for (const el of this.getFormElements()) {
        if (!el.checkValidity()) {
          el.focus()
          el.reportValidity()

          return
        }
      }

      this.confirm()
    },

    updateInfos() {
      const newInfos = []

      const formElements = this.getFormElements()

      if (!formElements?.length) return

      formElements.forEach(input => {
        let value = input.value

        if (input.tagName === 'SELECT' && !!value) {
          const selectedOption = input.selectedOptions[0]

          value = selectedOption ? selectedOption.textContent : ''
        }

        newInfos.push({ label: input.labels[0]?.textContent, value })
      })

      if (JSON.stringify(this.infos) != JSON.stringify(newInfos)) {
        this.infos = newInfos
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.step-card {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  background-color: $white-color;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.error {
    border: 1px solid $red-color;

    .line {
      &::after {
        background-color: $red-color;
      }

      .title {
        color: $red-color;
      }
    }
  }

  .line {
    position: relative;
    padding: 1.5rem 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    &::after {
      content: "";
      border-radius: 0 4px 4px 0;
      width: 0.5rem;
      height: 60%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-color: $primary-color;
    }

    .title {
      margin: 0;
      font-size: 2rem;
      font-weight: 500;
      color: $primary-color;
    }

  }

  .content {
    padding-inline: 2.5rem;
    padding-bottom: 1.5rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .infos {
      display: grid;
      grid-template-rows: repeat(auto, 1fr);
      gap: 1rem;

      .info {
        .info-title {
          margin-bottom: 0.2rem;
          font-size: 1.5rem;
          font-weight: 550;
          color: $second-color-light;
        }

        .info-content {
          margin: 0;
          font-size: 1.8rem;
          font-weight: 500;
        }
      }
    }

    .expansible {
      :deep(h3) {
        color: $primary-color;
        font-size: 1.8rem;
        font-weight: 400;
        width: 100%;
        border-bottom: 1.5px solid $primary-color;
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;

      button {
        margin: 0;
      }
    }
  }
}
</style>
