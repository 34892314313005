<template lang="pug">
.overview-container(v-if="contract")
  .buttons-container
    button.destroy-contract(
      v-if="contract.discardable && !contract.discarded",
      @click="showConfirmationModal"
    )
      | {{ $t('.actions.disable') }}

    dropdown(
      :urls="translateDropDownUrls"
      :callbacks="dropDownCallbacks"
      :title="$t('.actions.dropDown.title')"
    )

  expansible-card(:title="$t('.contractInfo')", :expanded="true")
    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.code') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.code || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.createdAt') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.createdAt")
          | {{ this.$l('date.formats.default', contract.createdAt) }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.startDate') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.startDate")
          | {{ this.$l('date.formats.default', contract.startDate) }}
        p.info-content(v-else) -

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.monthsUntilDeadline') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.monthsUntilDeadline || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.dueDay') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ ("0" + contract.dueDay).slice(-2) || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.readjustmentMonth') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.readjustmentMonth")
          | {{ this.$t('date.month_names')[contract.readjustmentMonth] }}
        p.info-content(v-else) -

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.rentValue') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) R$ {{ contract.rentValue || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.contractType') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.contractType")
          | {{ $t(`models.contract.contractType.${contract.contractType}`) }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.readjustmentIndex') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.readjustmentIndex")
          | {{ $t(`models.contract.readjustmentIndex.${contract.readjustmentIndex}`)}}
        p.info-content(v-else) -

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.paymentType') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.paymentType")
          | {{ $t(`models.contract.paymentType.${contract.paymentType}`) }}
        p.info-content(v-else) -

    .inline-section
      .full-size
        h3.info-title {{ $t('models.contract.attributes.observation') }}
        .loading-content.observation(v-if="loading")
        p.info-content(v-else) {{ contract.observation || '-' }}

  expansible-card(:title="$t('.billingInfo')")
    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.fireInsuranceAmount') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) R$ {{ contract.fireInsuranceAmount || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.fireInsuranceInstallments') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.fireInsuranceInstallments || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.condominiumAmount') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) R$ {{ contract.condominiumAmount || '-' }}

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.iptuAmount') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) R$ {{ contract.iptuAmount || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.iptuInstallments') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.iptuInstallments || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.iptuExemptionMonths') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.iptuExemptionMonths || '-' }}

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.rentDiscount') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) R$ {{ contract.rentDiscount || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.rentDiscountMonths') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.rentDiscountMonths || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.closedMonth') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.closedMonth == 'closed'")
          | {{ $t('.values.yes') }}
        p.info-content(v-else) {{ $t('.values.no') }}

  expansible-card(:title="$t('.landlordInfo')")
    .inline-section
      .four-columns
        h3.info-title {{ $t('models.contract.attributes.landlordName') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.landlordAttributes.name || '-' }}

      .four-columns
        h3.info-title {{ $t('models.contract.attributes.landlordCpfCnpj') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.landlordAttributes.cpfCnpj || '-' }}

  expansible-card(:title="$t('.tenantInfo')")
    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.name') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.tenantAttributes.name || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.cpf') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.tenantAttributes.cpf || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.rg') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.tenantAttributes.rg || '-' }}

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.firstEmail') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.tenantAttributes.emailsAttributes[0]")
          | {{ contract.tenantAttributes.emailsAttributes[0].address }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.secondEmail') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.tenantAttributes.emailsAttributes[1]")
          | {{ contract.tenantAttributes.emailsAttributes[1].address }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.thirdEmail') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.tenantAttributes.emailsAttributes[2]")
          | {{ contract.tenantAttributes.emailsAttributes[2].address }}
        p.info-content(v-else) -

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.firstPhone') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.tenantAttributes.phonesAttributes[0]")
          | {{ contract.tenantAttributes.phonesAttributes[0].number }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.secondPhone') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.tenantAttributes.phonesAttributes[1]")
          | {{ contract.tenantAttributes.phonesAttributes[1].number }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.thirdPhone') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.tenantAttributes.phonesAttributes[2]")
          | {{ contract.tenantAttributes.phonesAttributes[2].number }}
        p.info-content(v-else) -

  expansible-card(:title="$t('.propertyInfo')")
    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.propertyType') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.propertyAttributes.propertyType")
          | {{ $t(`models.contract.attributes.propertyTypeOptions.${contract.propertyAttributes.propertyType}`) }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.zipCode') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.propertyAttributes.zipCode || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.state') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.propertyAttributes.state || '-' }}

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.city') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.propertyAttributes.city || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.neighborhood') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.propertyAttributes.neighborhood || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.street') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.propertyAttributes.street || '-' }}

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.number') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.propertyAttributes.number || '-'}}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.complement') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.propertyAttributes.complement || '-' }}

  confirmation-modal(
    @close="showModal = false",
    :show="showModal",
    :loading="loadingModal",
    :title="$t('.modals.removeContractModal.title')",
    :message="$t('.modals.removeContractModal.message')",
    :callback="removeContract"
  )
  confirmation-modal(
    @close="showDiscardErrorModal = false",
    :show="showDiscardErrorModal",
    :title="$t('.modals.removeContractErrorModal.title')",
    :message="$t('.modals.removeContractErrorModal.message')"
  )
  report-modal(
    :show="showReportModal",
    :reportApiUrl="currentReportUrl",
    :callback="() => showReportModal = false"
  )
  confirmation-modal(
    :show="showTransfersNotFoundModal",
    :title="$t('.modals.transfersNotFoundModal.title')",
    :message="$t('.modals.transfersNotFoundModal.message')",
    @close="showTransfersNotFoundModal = false"
  )
</template>
<script>
import objects from "lib/objects"

import ExpansibleCard from "../expansible-card.vue"
import ConfirmationModal from "../../../components/confirmation-modal.vue"
import Dropdown from "../../../components/dropdown.vue"
import ReportModal from "../../../../../components/report-modal.vue"

export default {
  components: { ExpansibleCard, ConfirmationModal, Dropdown, ReportModal },

  props: {
    loading: { type: Boolean, required: false },
    contract: { type: Object, required: true }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.management.contract.show",

      showModal: false,
      showDiscardErrorModal: false,
      loadingModal: false,

      showReportModal: false,
      currentReportUrl: null,
      showTransfersNotFoundModal: false,
    }
  },

  computed: {
    translateDropDownUrls() {
      let dropDownItems = []

      this.contract.filesUrls.forEach(file => {
        if (!file) return

        dropDownItems.push({ name: file.fileName, address: file.address })
      })

      return dropDownItems
    },

    dropDownCallbacks() {
      return [{
        name: this.$t('.actions.dropDown.items.transferCompositionReport'),
        callback: this.generateReport
      }]
    }
  },

  methods: {
    showConfirmationModal() {
      this.showModal = true
    },

    generateReport() {
      const path = "pendant-and-consolidated-expenses-reports"

      this.$http
        .get(
          `${this.$user.prefixUrlAgency}/transfers/downloads/${path}`,
          { params:  { contract_id: this.contract.id } }
        )
        .then(({ data }) => {
          const report = objects.camelize(data.data)

          this.currentReportUrl = `${this.$user.prefixUrl}/reports/${report.id}`
          this.showReportModal = true
        })
        .catch(() => {
          this.showTransfersNotFoundModal = true
        })
    },

    removeContract() {
      this.loadingModal = true

      this.$http
        .delete(`${this.$user.prefixUrlAgency}/contracts/${this.contract.id}`)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.removeContract.failure"))
          this.showDiscardErrorModal = true
        })
        .finally(() => {
          this.showModal = false
          this.loadingModal = false
        })
    }
  },
}
</script>

<style lang="scss">
.contract-container-desktop {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
.loading-content {
  background: $loading-gradient;
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  width: 100%;
  height: 3.5rem;
  margin-bottom: 1.5rem;

  &.observation {
    height: 15rem;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.info-title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: $second-color-light;
}

.info-content {
  font-size: 1.5rem;
  font-family: Lato;
}

.inline-section {
  display: flex;
  justify-content: space-between;
  word-break: break-word;

  .one-column {
    width: 10%;
  }

  .two-columns {
    width: 47%;
  }

  .three-columns {
    width: 30%;
  }

  .four-columns {
    width: 52%;
  }

  .eleven-columns {
    width: 90%;
  }

  .full-size {
    width: 100%;
  }
}

.buttons-container {
  display: flex;
  gap: 1.5rem;

  button {
    text-align: center;
    color: $second-color-light;
    font-weight: 400;
    border-radius: 0.5rem;
    border: none;
    padding: 0 1.5rem;
    height: auto;
    width: fit-content;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: none;

    &.transfer-composition-report {
      color: $blue-color;
      background-color: lighten($blue-color, 36%);

      &:hover {
        background-color: lighten($blue-color, 31%);
        border-radius: 0.5rem;
      }
    }

    &.destroy-contract {
      color: $red-color;
      background-color: lighten($red-color, 54%);

      &:hover {
        background-color: lighten($red-color, 49%);
      }
    }
  }
}
</style>