<template lang="pug">
#page.users
  .users-index
    .users-index-container
      card
        .filtering-container
          h2 {{ $t('.titles.index') }}

          .actions
            button.icon.button-blue(@click="showNewAttendantModal = true")
              i.fa.fa-plus

        .table-section
          .table-container(@scroll="autoLoad")
            table
              thead
                tr
                  th(v-for="name in columnNames")
                    p {{ $t(`.table.columns.${name}`) }}

              tbody
                tr(v-for="user in users")
                  td.id
                    | {{ user.id }}

                  td.name
                    | {{ user.name }}

                  td.email
                    | {{ user.email }}

                  td.role
                    | {{ $t(`models.realEstateUser.roles.${user.role}`) }}

                  td.created-at
                    | {{ $l('date.formats.default', user.createdAt) }}

                  td.confirmed-at
                    | {{ $l('date.formats.default', user.confirmedAt) }}

                  td.last-sign-in-at
                    | {{  $l('date.formats.default', user.lastSignInAt) }}

                  td.actions
                    button.button-blue(
                      @click="() => { showEditModal = true; currentUser = user }",
                    )
                      i.fa.fa-edit
                    button.button-red(
                      @click="() => { showDeleteModal = true; currentUser = user }"
                    )
                      i.fa.fa-trash

                tr.loading(v-if="loading")
                  td(v-for="cell in columnNames")
                    .cell
          footer
            p {{ paginationText }}

  confirmation-modal(
    @close="showDeleteModal = false",
    :show="showDeleteModal",
    :title="$t('.deleteModal.title')",
    :message="$t('.deleteModal.message', { name: currentUser?.name })",
    :callback="deleteUser"
  )

  create-modal(
    :show="showNewAttendantModal"
    :agencyId="getAgencyId"
    :callback="addNewAttendantDataInTable"
    @close="showNewAttendantModal = false"
  )

  edit-modal(
    :show="showEditModal"
    :user="currentUser"
    :agencyId="getAgencyId"
    :callback="updateUser"
    @close="showEditModal = false"
  )
</template>
<script>
import CreateModal from "./create-modal.vue"
import EditModal from "./edit-modal.vue"
import objects from "lib/objects"
import ConfirmationModal from "../../../../components/confirmation-modal.vue"

export default {
  components: { CreateModal, ConfirmationModal, EditModal },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      defaultI18nScope: "admin.realEstateAgencies.users.index",

      columnNames: [
        "id",
        "name",
        "email",
        "role",
        "createdAt",
        "confirmedAt",
        "lastSignInAt",
        "actions"
      ],

      // table
      users: [],
      pagination: { total: 0 },

      // modal
      showNewAttendantModal: false,
      showDeleteModal: false,
      showEditModal: false,
      currentUser: null,

      loading: false
    }
  },

  methods: {
    init(users, pagination) {
      this.users.push(...objects.camelize(users))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total")
      }
    },

    deleteUser() {
      this.$http
        .delete(`${this.usersPath}/${this.currentUser.id}`)
        .then(() => {
          this.users = this.users.filter(({ id }) => id !== this.currentUser.id)

          this.$notifications.info(this.$t(".notifications.delete.success"))
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.delete.failure"))
        }).finally(() => {
          this.showDeleteModal = false
        })
    },

    updateUser(user) {
      const index = this.users.findIndex(({ id }) => id === user.id)

      this.users.splice(index, 1, user)
    },

    fetch(page) {
      this.loading = true

      this.$http
        .get(this.usersPath, { params: this.httpParams(page) })
        .then(({ data }) => {
          const users = data.data
          const pagination = data.pagination || {}

          this.init(users, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    addNewAttendantDataInTable(attendant) {
      return this.users.push(objects.camelize(attendant))
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({
        id: this.id,
        name: this.name,
        email: this.email,
        created_at: this.createdAt,
        confirmed_at: this.confirmedAt,
        last_sign_in_at: this.lastSignInAt,
        page: page
      })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    }
  },

  computed: {
    getAgencyId() {
      return this.$route.params.id
    },

    usersPath() {
      return `/admins/real-estate/agencies/${this.getAgencyId}/users`
    },

    paginationText() {
      return this.$t(".pagination.text", {
        length: this.users.length,
        total: this.pagination.total
      })
    }
   }
}
</script>
<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: repeat(3, auto) repeat(2, max-content);
  column-gap: 0.5em;

  .button-primary {
    margin-bottom: 1.4rem;
    margin-top: auto;
  }

  .new-bill-container {
    margin-bottom: 1.4rem;
    margin-top: auto;

    padding-left: 0.5em;
    border-left: 2px solid $grey-color;

    .button-blue {
      margin: 0;
      padding: 0 1.5rem;
    }
  }
}

.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary-color;
  }

  .actions {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  p,
  button {
    margin: 0;
  }
}

.table-section {
  margin-top: 1.5rem;
}

.table-container {
  height: 40vh !important;
}

table {
  tbody {
    .actions {
      button {
        margin: 0;
        width: 3.8rem;
        height: 3.8rem;

        padding: 0;

        &:last-child {
          margin-left: 0.5rem;
        }
      }

      i {
        font-size: 1.8rem;
      }
    }

    td.id, td.actions {
      min-width: fit-content !important;
    }

    tr {
      &:hover {
        td {
          background-color: darken($white-color-dark, 6%);
        }
      }
    }
  }
}
</style>
