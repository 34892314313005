<template lang="pug">
#page.bill-show
  navbar(currentPage="realEstateManagementIndex")

  loading-div.bill-container-desktop
    .bill-container
      breadcrumbs(:items="breadcrumbItems")

      .buttons-container
        button.transfer-composition-report(@click="generateReport")
          | {{ $t('.actions.transferCompositionReport') }}

      expansible-card(:title="$t('.titles.billDetails')", :expanded="true")
        bill-details(:bill="bill" :loading="loading")

      expansible-card(:title="$t('.titles.billItemsTable')", :expanded="true")
        .table-section
          .table-container(@scroll="autoLoad")
            table
              thead
                tr
                  th(v-for="name in columnNames")
                    p {{ $t(`.table.${name}`) }}

              tbody
                tr(v-for="billItem in billItems")
                  td.bill-item-description {{ billItem.description || '-' }}

                  td.bill-item-value {{ `R$ ${formatValue(billItem.value)}` }}

                  td.bill-item-complement {{ billItem.complement || '-' }}

          footer
            p {{ paginationText }}

  report-modal(
    :show="showReportModal",
    :reportApiUrl="currentReportUrl",
    :callback="() => showReportModal = false"
  )
  confirmation-modal(
    :show="showTransfersNotFoundModal",
    :title="$t('.modals.transfersNotFoundModal.title')",
    :message="$t('.modals.transfersNotFoundModal.message')",
    @close="showTransfersNotFoundModal = false"
  )
</template>
<script>
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"
import objects from "lib/objects"

import LoadingCard from '../../../../../components/loading-card.vue'
import Breadcrumbs from '../../../../../components/breadcrumbs.vue'
import ExpansibleCard from "../../../../components/expansible-card.vue"
import ReportModal from "../../../../../../../components/report-modal.vue"
import ConfirmationModal from "../../../../../components/confirmation-modal.vue"
import BillDetails from "../../../../components/contract/bill/bill-details.vue"
import Navbar from "../../../../components/navbar.vue"

export default {
  mixins: [tool, vueAuthenticate],
  components: {
    LoadingCard,
    Breadcrumbs,
    Navbar,
    ExpansibleCard,
    BillDetails,
    ReportModal,
    ConfirmationModal
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.management.contract.bill.show",

      // tool
      routeName: "realEstateContractBillShow",
      routePath: 'imobiliaria/gerenciamento/contratos',
      viewName: "show",
      submitting: false,
      loading: false,

      // bill
      bill: {},

      //bill items
      billItems: [],
      pagination: { total: 0 },
      columnNames: ['description', 'value', 'complement'],

      // report modal
      showReportModal: false,
      currentReportUrl: null,
      showTransfersNotFoundModal: false
    }
  },

  computed: {
    billPath() {
      return `${this.$user.prefixUrlAgency}/contracts/${this.getContractParam}/bills/${this.getBillParam}`
    },

    getContractParam() {
      return this.$route.params.contractId
    },

    getBillParam() {
      return this.$route.params.billId
    },

    breadcrumbItems() {
      const routeManagement = this.routePath.replace('/contratos', '')
      const routeContract = `${this.routePath}/${this.getContractParam}`
      const routeBill = `${routeContract}/cobrancas/${this.getBillParam}`

      return [
        {
          name: this.$t('.breadcrumbs.management'),
          url: routeManagement
        },
        {
          name: this.$t('.breadcrumbs.contracts'),
          url: routeManagement,
          hash: 'contracts'
        },
        {
          name: this.$t('.breadcrumbs.billing', { contractId: this.getContractParam }),
          url: routeContract
        },
        {
          name: `#${this.getBillParam}`,
          url: routeBill
        }
      ]
    },

    paginationText() {
      return this.$t(
        '.pagination.text',
        { length: this.billItems.length, total: this.pagination.total }
      )
    }
  },

  methods: {
    parseRoute() {},

    fetch() {
      this.loading = true

      return this.$http
        .get(this.billPath)
        .then(({ data }) => {
          this.bill = objects.camelize(data)

          this.fetchBillItems()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    initBillItems(data, pagination) {
      this.billItems.push(...objects.camelize(data))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    fetchBillItems() {
      this.loading = true

      return this.$http
        .get(`${this.billPath}/bill_items`)
        .then((response) => {
          const body = response.data
          const billItems = body.data
          const pagination = body.pagination || {}

          this.initBillItems(billItems, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetchBillItems.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target

      if ((scrollTop + clientHeight < scrollHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetchBillItems(this.pagination.next)
    },

    formatValue(value) {
      return value ? value.toString().replace('.', ',') : ''
    },

    generateReport() {
      const path = "pendant-and-consolidated-expenses-reports"

      this.$http
        .get(
          `${this.$user.prefixUrlAgency}/transfers/downloads/${path}`,
          { params: { bill_id: this.getBillParam } }
        )
        .then(({ data }) => {
          const report = objects.camelize(data.data)

          this.currentReportUrl = `${this.$user.prefixUrl}/reports/${report.id}`
          this.showReportModal = true
        })
        .catch(() => {
          this.showTransfersNotFoundModal = true
        })
    }
  }
}
</script>

<style lang="scss">
.bill-container-desktop {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
@import "src/styles/base/tables.scss";

#page.bill-show {
  overflow: hidden;
}

.bill-container-desktop {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  max-width: fit-content;
  height: 100%;
  overflow: hidden;

  .bill-container {
    width: 100%;
    height: 100vh;
    padding: 2.5rem 7rem 10rem 7rem;
    overflow-y: auto;

    .buttons-container {
      display: flex;
      gap: 1.5rem;

      button {
        text-align: center;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0 1.5rem;
        height: auto;
        width: fit-content;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.transfer-composition-report {
          color: $blue-color;
          background-color: lighten($blue-color, 36%);

          &:hover {
            background-color: lighten($blue-color, 31%);
            border-radius: 0.5rem;
          }
        }

        &.destroy-contract {
          color: $red-color;
          background-color: lighten($red-color, 54%);

          &:hover {
            background-color: lighten($red-color, 49%);
          }
        }
      }
    }
  }
}

.table-section {
  p {
    margin: 0;
  }

  .table-container {
    height: auto !important;
    max-height: 40vh;

    table {
      tbody {
        tr {
          height: 5rem !important;
        }
      }

      td {
        text-transform: capitalize !important;
      }

      .bill-item-description, .bill-item-value {
        min-width: 13rem !important;
      }

      .bill-item-complement {
        width: 100%;
      }
    }
  }
}
</style>