import CryptoJS from 'crypto-js'

const envSecretKey = process.env.VUE_APP_ENCRYPTION_KEY

const key = CryptoJS.enc.Utf8.parse(envSecretKey)

const cryptography = {
  encrypt(data) {
    if (!data) return

    // Generates a random IV (16 bytes for AES)
    // IV stands for Initialization Vector and is used as a encryption salt
    const iv = CryptoJS.lib.WordArray.random(128 / 8)

    const encrypted = CryptoJS.AES.encrypt(
      data,
      key,
      {
        iv,
        mode: CryptoJS.mode.CBC, // Cipher Block Chaining mode
        padding: CryptoJS.pad.Pkcs7 // PKCS7 padding
      }
    )
    // It returns an object of type WordArray
    // (that's why we need to convert it to a string)

    // Concatenate the IV and the encrypted data to send it to the server
    // (we will need the IV to decrypt the data)
    const encryptedDataWithIv = CryptoJS.enc.Base64
      .stringify(iv.concat(encrypted.ciphertext))

    return encryptedDataWithIv
  },

  decrypt(encryptedEncoded64Data) {
    if (!encryptedEncoded64Data) return

    const decodedData = CryptoJS.enc.Base64.parse(encryptedEncoded64Data)

    // Extract the IV - the first 16 bytes
    const iv = CryptoJS.lib.WordArray.create(decodedData.words.slice(0, 4), 16)

    // Extract the encrypted data - the rest of the bytes
    const encryptedData = CryptoJS.lib.WordArray
      .create(decodedData.words.slice(4), decodedData.sigBytes - 16)

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedData },
      key,
      {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    )

    return CryptoJS.enc.Utf8.stringify(decrypted)
  }
}

export default cryptography