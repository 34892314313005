<template lang="pug">
.digisac-messages-modal
  Modal(:show="show" @close="close")
    h1.title  {{ $t('.title') }}

    .table-section
      .table-container
        table
          thead
            tr
              th(v-for="name in columnNames")
                p {{ $t(`.details.table.${name}`) }}

          tbody
            tr(v-for="message in messages")
              td {{ message.phoneNumber }}
              td {{ getAckTranslation(message.ack) }}
              td {{ $l('time.formats.micro', message.createdAt) }}
              td {{ $l('time.formats.micro', message.updatedAt) }}
              td.actions
                button.button-blue(@click="() => { updateMessage(message.id) }")
                  i.fa.fa-refresh

    .closed-button
      button.button(@click="close") {{ $t('.button.close') }}

</template>
<script>
import { ref } from 'vue'
import objects from "lib/objects"

import Modal from '../../../../../components/modal.vue'

export default {
  components: { Modal },

  setup () {
    const isShow = ref(false)

    function showModal () {
      this.fetch()

      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.bills.digisacMessagesModal",

      columnNames: [
        'phoneNumber',
        'ack',
        'createdAt',
        'updatedAt',
        'update'
      ],

      messages: []
    }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  props: {
    billId: { type: Number, required: true },
    show: { type: Boolean, default: false }
  },

  methods: {
    init(data) {
      this.messages = objects.camelize(data)
    },

    close() {
      this.$emit('close')
    },

    getAckTranslation(ack) {
      return ack ? this.$t(`.ack.${ack}`) : '-'
    },

    fetch() {
      this.$http.get(`/admins/bills/${this.billId}/digisac-messages.json`)
        .then((response) => {
          const body = response.data

          this.init(body)
        })
        .catch(() => {
          this.close()

          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
    },

    updateMessage(id) {
      this.$http.put(`/admins/digisac/messages/${id}`)
        .then((response) => {
          const body = response.data

          const index = this.messages.findIndex((message) => {
            return message.id === body.id
          })

          this.messages[index] = objects.camelize(body)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.update.failure"))
        })
    }
  },
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 1.7rem;
  font-weight: 550;
  color: $second-color-dark;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  padding: 1rem 0;
}

.actions {
  min-width: fit-content;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  button {
    margin: 0;
    width: 3.8rem;
    padding: 0;
  }

  i {
    font-size: 1.8rem;
  }
}

.closed-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

footer {
  p {
    margin: 0;
  }
}

.table-container {
  max-height: 40vh;

  table {
    tbody {
      tr {
        height: 5rem !important;
      }
    }

    td {
      text-transform: none !important;
    }
  }
}
</style>
