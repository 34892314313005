
import Home from '../views/static_pages/home.vue'
import ForRealEstateAgencies from '../views/static_pages/for-real-estate-agencies.vue'
import ForLandlords from '../views/static_pages/for-landlords.vue'
import ForTenants from '../views/static_pages/for-tenants.vue'
import Contact from '../views/static_pages/contact.vue'
import PartnershipTerm from '../views/static_pages/partnership-term.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/para-imobiliarias',
    name: 'forRealEstateAgencies',
    component: ForRealEstateAgencies
  },
  {
    path: '/para-proprietarios',
    name: 'forLandlords',
    component: ForLandlords
  },
  {
    path: '/para-locatarios',
    name: 'forTenants',
    component: ForTenants
  },
  {
    path:      '/contato',
    name:      'contact',
    component: Contact
  },
  {
    path:     '/termo-de-parceria',
    name:     'partnershipTerm',
    component: PartnershipTerm
  }
]

export default routes
