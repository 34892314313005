<template lang="pug">
.score-steps-container
  .score-steps
    h2.title {{ $t('.titles.menu') }}
    h2.title {{ titleDetailTranslate }}

    .menu-steps
      .step-group(v-for="(steps, indexGroup) in groupedSteps")
        .index-container(:class="indexClass(steps)")
          p.index {{ indexGroup + 1 }}

        .steps-container
          p.step(
            v-for="step, indexStep in steps",
            :class="stepClass(step)",
            @click="setStepAsCurrent(step, indexGroup, indexStep)"
          )
            | {{ step.formattedName || '-' }}

    step-details(
      v-if="hasPerformedSteps",
      :scoreId="score.id",
      :currentStep="currentStep"
    )

  .buttons-container
    button(
      :class="currentIndexMenu == 0 ? 'button-grey' : 'button-primary'",
      :disabled="currentIndexMenu == 0",
      @click="toPreviousStep"
    )
      | {{ $t('.buttons.toPreviousStep') }}

    button(
      :class="disableToNextStepButton ? 'button-grey' : 'button-primary'",
      :disabled="disableToNextStepButton",
      @click="toNextStep"
    )
      | {{ $t('.buttons.toNextStep') }}

    button.button-primary(
      v-if="status == 'disapproved' && !clone && !areAllStepsPerformed",
      :disabled="disablePerformNextStepButton",
      @click="showPerformNextStepModal = true"
    )
      | {{ $t('.buttons.performNextStep') }}

  confirmation-modal(
    @close="showPerformNextStepModal = false",
    :show="showPerformNextStepModal",
    :title="$t('.modal.performNextStep.title')",
    :message="messagePerformNextStepModal",
    :callback="performNextStep"
  )
</template>
<script>
import objects from "lib/objects"

import ConfirmationModal from "../../../../components/confirmation-modal.vue"
import StepDetails from "./score_steps/details.vue"

export default {
  components: { ConfirmationModal, StepDetails },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.scoreCard.scoreSteps",

      // score steps
      groupedSteps: [],
      scoreSteps: [],
      currentIndexMenu: 0,

      // modal
      showPerformNextStepModal: false,
      loading: false
    }
  },

  props: {
    score: { type: Object, required: true },
    clone: { type: Boolean },
    status: { type: String }
  },

  beforeMount() {
    if (this.status == 'wip') this.loading = true

    this.fetchScoreSteps()
  },

  computed: {
    performedSteps() {
      return this.score.steps
    },

    hasPerformedSteps() {
      return this.performedSteps.length > 0
    },

    lastStepPerformed() {
      return this.performedSteps.slice(-1)[0]
    },

    areAllStepsPerformed() {
      return this.stepsWerePerformed(this.scoreSteps)
    },

    currentStep() {
      return this.scoreSteps[this.currentIndexMenu] || {}
    },

    nextStep() {
      return this.scoreSteps[this.currentIndexMenu + 1]
    },

    titleDetailTranslate() {
      return this.$t(
        '.titles.details', { step: this.currentStep.formattedName || '' }
      )
    },

    disableToNextStepButton() {
      return this.nextStep ? !this.stepWasPerformed(this.nextStep) : true
    },

    disablePerformNextStepButton() {
      if (this.loading) return true

      return this.nextStep ? this.stepWasPerformed(this.nextStep) : false
    },

    messagePerformNextStepModal() {
      return this.$t(
        '.modal.performNextStep.message',
        { step: this.nextStep && this.nextStep.formattedName }
      )
    }
  },

  methods: {
    toNextStep() {
      this.currentIndexMenu++
    },

    toPreviousStep() {
      this.currentIndexMenu--
    },

    setStepAsCurrent(step, indexGroup, indexStep) {
      if (!this.stepWasPerformed(step)) return

      this.currentIndexMenu = (
        indexGroup > indexStep ? indexGroup + 1 : indexStep
      )
    },

    stepWasPerformed(step) {
      return this.performedSteps.some(
        performedStep => performedStep.id == step.id
      )
    },

    stepsWerePerformed(steps) {
      let performedCount = 0

      steps.forEach(step => this.stepWasPerformed(step) && performedCount++)

      return performedCount == steps.length
    },

    indexClass(steps) {
      if (
        this.hasPerformedSteps
        && steps.some(({ id }) => id == this.currentStep.id)
      ) {
        return 'actived'
      }

      if (!this.stepsWerePerformed(steps)) return 'disabled'
    },

    stepClass(step) {
      if (this.hasPerformedSteps && step.id == this.currentStep.id) {
        return 'actived'
      }

      if (!this.stepWasPerformed(step)) return 'disabled'
    },

    initScoreSteps(scoreSteps) {
      this.scoreSteps =  objects.camelize(scoreSteps)

      this.groupedSteps = this.scoreSteps
        .reduce((array, step) => {
          const index = step.indexOrder - 1

          if (!array[index]) array[index] = []

          array[index].push(step)

          return array
        }, [])
        .filter(group => group && group.length > 0)
    },

    fetchScoreSteps() {
      return this.$http
        .get(`/admins/scores/${this.score.id}/steps.json`).then(({ data }) => {
          this.initScoreSteps(data)
        }).catch(() => {
          this.$notifications.error(
            this.$t(".notifications.fetchScoreSteps.failure")
          )
        })
    },

    performNextStep() {
      this.loading = true
      this.showPerformNextStepModal = false

      return this.$http
        .post(
          `/admins/scores/${this.score.id}/steps.json`,
          { score_step_id: this.nextStep.id }
        )
        .then(() => {

          this.$notifications.info(
            this.$t(".notifications.performNextStep.success")
          )

          this.$emit('re-fetch')
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.performNextStep.failure")
          )
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped lang="scss">
.score-steps-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.score-steps {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: auto 1fr;
  gap: 1.5rem;
  min-height: 40rem;

  .title {
    margin-bottom: 0;
    font-size: 2rem;
    font-weight: 500;
    border-bottom: 1.5px solid $primary-color;
    color: $primary-color;
  }

  .menu-steps {
    p {
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: none;
      border-radius: 0.5rem;
      padding: 0.5rem 1.5rem;
      color: $second-color-light;
    }

    .step-group {
      display: flex;
      gap: 1rem;

      .index-container {
        background-image: linear-gradient($second-color-light, $second-color-light);
        background-size: 2px 100%;
        background-repeat: no-repeat;
        background-position: center center;

        .index {
          text-align: center;
          background-color: $grey-color-light;
          margin: 0;
        }

        &.actived {
          background-image: linear-gradient($primary-color, $primary-color);

          .index {
            background-color: $primary-high-light;
            color: $primary-color;
          }
        }

        &.disabled {
          background-image: linear-gradient($grey-color, $grey-color);

          .index {
            background-color: $grey-color-high-light;
            color: $grey-color;
          }
        }
      }

      .steps-container {
        width: 100%;

        .step {
          text-align: start;
          width: 100%;
          margin-bottom: 1rem;
          cursor: pointer;

          &.actived {
            background-color: $primary-high-light;
            color: $primary-color;
          }

          &.disabled {
            color: $grey-color;
          }
        }

      }

      &:last-child{
        .step {
          margin-bottom: 0;
        }
      }
    }
  }
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  button {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 1.4rem;
  }
}
</style>
