<template lang="pug">
.score-steps-container
  .score-steps
    .menu-steps
      .step-group(v-for="(steps, indexGroup) in groupedSteps")
        .index-container(:class="indexClass(steps)")
          p.index {{ indexGroup + 1 }}

        .steps-container
          p.step(
            v-for="step, indexStep in steps",
            :class="stepClass(step)",
            @click="selectStep(step, indexGroup, indexStep)"
          )
            | {{ step.formattedName || '-' }}
            i.fa.fa-refresh(v-if="!clone && isNextStepToPerform(step.id)")

  step-details(
    v-if="hasPerformedSteps",
    @close="showStepDetail = false",
    :show="showStepDetail"
    :scoreId="score.id",
    :currentStep="currentStep"
  )

  confirmation-modal(
    @close="showPerformNextStepModal = false",
    :show="showPerformNextStepModal",
    :title="$t('.modal.performNextStep.title')",
    :message="messagePerformNextStepModal",
    :callback="performNextStep"
  )
</template>
<script>
import objects from "lib/objects"

import ConfirmationModal from "../../../../components/confirmation-modal.vue"
import StepDetails from "./score_steps/details.vue"

export default {
  components: { ConfirmationModal, StepDetails },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.scoreCard.scoreSteps",

      // score steps
      groupedSteps: [],
      scoreSteps: [],
      currentIndexMenu: 0,

      // modal
      showStepDetail: false,
      showPerformNextStepModal: false,
      loading: false
    }
  },

  props: {
    score: { type: Object, required: true },
    clone: { type: Boolean },
    status: { type: String }
  },

  beforeMount() {
    if (this.status == 'wip') this.loading = true

    this.fetchScoreSteps()
  },

  computed: {
    performedSteps() {
      return this.score.steps
    },

    hasPerformedSteps() {
      return this.performedSteps.length > 0
    },

    lastStepPerformed() {
      return this.performedSteps.slice(-1)[0]
    },

    areAllStepsPerformed() {
      return this.stepsWerePerformed(this.scoreSteps)
    },

    currentStep() {
      return this.scoreSteps[this.currentIndexMenu] || {}
    },

    titleDetailTranslate() {
      return this.$t(
        '.titles.details', { step: this.currentStep.formattedName || '' }
      )
    },

    messagePerformNextStepModal() {
      return this.$t(
        '.modal.performNextStep.message',
        { step: this.nextStepToPerform?.formattedName }
      )
    },

    nextStepToPerform() {
      const stepsNotPerformed = this.scoreSteps.filter(
        step => !this.stepWasPerformed(step)
      )

      return stepsNotPerformed[0]
    }
  },

  methods: {
    isNextStepToPerform(id) {
      return this.nextStepToPerform?.id == id
    },

    selectStep(step, indexGroup, indexStep) {
      if (this.stepWasPerformed(step)) {
        this.currentIndexMenu = (
          indexGroup > indexStep ? indexGroup + 1 : indexStep
        )

        this.showStepDetail = true

        return
      }

      if (!this.clone && isNextStepToPerform(step.id)) {
        this.showPerformNextStepModal = true
      }
    },

    stepWasPerformed(step) {
      return this.performedSteps.some(
        performedStep => performedStep.id == step.id
      )
    },

    stepsWerePerformed(steps) {
      let performedCount = 0

      steps.forEach(step => this.stepWasPerformed(step) && performedCount++)

      return performedCount == steps.length
    },

    indexClass(steps) {
      if (this.hasPerformedSteps && this.stepsWerePerformed(steps)) {
        return 'actived'
      }

      return 'disabled'
    },

    stepClass(step) {
      if (this.hasPerformedSteps && this.stepWasPerformed(step)) {
        return 'actived'
      }

      return 'disabled'
    },

    initScoreSteps(scoreSteps) {
      this.scoreSteps =  objects.camelize(scoreSteps)

      this.groupedSteps = this.scoreSteps
        .reduce((array, step) => {
          const index = step.indexOrder - 1

          if (!array[index]) array[index] = []

          array[index].push(step)

          return array
        }, [])
        .filter(group => group && group.length > 0)
    },

    fetchScoreSteps() {
      return this.$http
        .get(`/admins/scores/${this.score.id}/steps.json`).then(({ data }) => {
          this.initScoreSteps(data)
        }).catch(() => {
          this.$notifications.error(
            this.$t(".notifications.fetchScoreSteps.failure")
          )
        })
    },

    performNextStep() {
      this.loading = true
      this.showPerformNextStepModal = false

      return this.$http
        .post(
          `/admins/scores/${this.score.id}/steps.json`,
          { score_step_id: this.nextStepToPerform.id }
        )
        .then(() => {
          this.$notifications.info(
            this.$t(".notifications.performNextStep.success")
          )

          this.$emit('re-fetch')
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.performNextStep.failure")
          )
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped lang="scss">
.score-steps-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.score-steps {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .menu-steps {
    p {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 38px;
      text-transform: none;
      border-radius: 0.5rem;
      padding-inline: 1.5rem;
      color: $second-color-light;
    }

    .step-group {
      display: flex;
      gap: 1rem;

      .index-container {
        background-image: linear-gradient($second-color-light, $second-color-light);
        background-size: 2px 100%;
        background-repeat: no-repeat;
        background-position: center center;

        .index {
          text-align: center;
          background-color: $grey-color-light;
          margin: 0;
        }

        &.actived {
          background-image: linear-gradient($primary-color, $primary-color);

          .index {
            background-color: $primary-high-light;
            color: $primary-color;
          }
        }

        &.disabled {
          background-image: linear-gradient($grey-color, $grey-color);

          .index {
            background-color: $grey-color-high-light;
            color: $grey-color;
          }
        }
      }

      .steps-container {
        width: 100%;

        .step {
          background-color: $grey-color-light;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 1.5rem;
          cursor: pointer;

          &.actived {
            background-color: $primary-high-light;
            color: $primary-color;
          }

          &.disabled {
            background-color: $grey-color-high-light;
            color: $grey-color;
          }
        }
      }

      &:last-child{
        .step {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
