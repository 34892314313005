import store from 'store'
import app from '../app'
import events from './events'

/**
 * Singleton para controle de autenticação.
 *
 * Eventos:
 *   - @login(user)
 *       Usuário acaba de se autenticar na aplicação
 *
 *   - @logout
 *       Usuário se "desautenticar" da aplicação
 *
 * @type {Object}
 */
const auth = {
  get user() {
    return app.store.get('user')
  },

  get user_id() {
    const id = app.store.get('user')?.id

    return id && Number(id)
  },

  get firstName() {
    let user = app.store.get('user')

    if (!user) return null
    return user.name.split(' ')[0]
  },

  get role() {
    return app.store.get('role')
  },

  get camelizedRole() {
    const role = app.store.get('role')

    if (!role) return null

    return _.camelCase(role)
  },

  /**
   * Autentica (registra) o usuário e seu token na aplicação.
   *
   * @param  {Object} user  Dados descritivos do usuário autenticado (ex: e-mail)
   */
  authenticate(user, role) {
    this.update(user, role)
    // Dispara evento para possíveis observers
    events.$emit('login', app.store.get('user'))
  },

  /**
   * Desautentica o usuário atualmente autenticado na aplicação
   */
  clear() {
    store.clearAll()

    // Dispara evento para possíveis observers
    events.$emit('logout')
  },

  isAuthenticated() {
    return !!app.store.get('user')
  },

  isRealEstateAttendantAuthenticated() {
    return !!app.store.get('user') && app.store.get('role') === 'real-estate-attendant'
  },

  isRealEstateManagerAuthenticated() {
    return !!app.store.get('user') && app.store.get('role') === 'real-estate-manager'
  },

  isRealEstateAdminAuthenticated() {
    return !!app.store.get('user') && app.store.get('role') === 'real-estate-admin'
  },

  isRealEstateUserAuthenticated() {
    return !!app.store.get('user') && [
      'real-estate-admin', 'real-estate-manager', 'real-estate-attendant'
    ].includes(app.store.get('role'))
  },

  isAdminAuthenticated() {
    return !!app.store.get('user') && app.store.get('role') === 'admin'
  },

  /**
   * Atualiza dados do usuário.
   *
   * @param  {Object} user  Dados descritivos do usuário autenticado (ex: e-mail)
   */
  update(user, role) {
    app.store.set('user', user)
    app.store.set('wildCard', user.wild_card)

    if (role == 'realEstate') role = `real-estate-${user.role}`

    app.store.set('role', _.kebabCase(role))

    events.$emit('update-user', user)
  }
}

export default auth
