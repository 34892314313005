<template lang="pug">
.field.select-field(:class="{ error, disabled: disabled || !agencyId }")
  label(v-if="!hideLabel", :for="inputId", :class="{ 'required': required }")
    | {{ labelText }}

  select2(
    :id="inputId",
    :disabled="disabled || !agencyId",
    :name="name",
    :options="selectedOption",
    :overlay="overlay",
    :placeholder="placeholder || defaultPlaceHolderText",
    type="integer",
    :value="modelValue",
    :include-blank="true",
    :hide-search="false",
    :required="required",
    @input="(val) => { $emit('update:modelValue', val) }",
    :url="url",
    :dataFormatting="dataFormatting",
    :processResults="processResults",
    :allowClear="allowClear"
  )
    slot
  input-error(:message="error")
</template>

<script>
import vueAuthenticate from "lib/vue-authenticate"
import SelectField from "src/components/desktop/select-field.vue"

export default {
  mixins: [vueAuthenticate],
  extends: SelectField,

  props: {
    agencyId: { type: Number },
    allowClear: { type: Boolean, default: false }
  },

  mounted() {
    if (this.modelValue) {
      this.$http
        .get(`${this.fetchUrl}/${this.agencyId}/users/${this.modelValue}`)
        .then(({ data }) => {
          this.selectedOption = [{ id: data.id, text: data.name }]
        })
    }
  },

  data() {
    return {
      defaultI18nScope: "admin.components.userField",

      selectedOption: [],

      fetchUrl: '/admins/real-estate/agencies'
    }
  },

  watch: {
    agencyId() {
      this.selectedOption = []
    }
  },

  computed: {
    url() {
      return `${this.fetchUrl}/${this.agencyId}/users`
    }
  },

  methods: {
    processResults({ data }) {
      const pagination = data.pagination || {}
      const users = data.data
      const results = users.map(({id, name}) => ({ id, text: name }))

      return { results, pagination: { 'more': !!pagination.next } }
    },

    dataFormatting(params) {
      return { page: params.page || 1, name: params.term }
    }
  },
}
</script>

<style scoped lang="scss">
label.required::after {
  content: ' *';
  color: $primary-color
}

.select-field.disabled {
  label {
    color: $grey-color-dark;
  }

  input {
    border-color: $grey-color-light;
    color: $grey-color-light;
  }

  .select2.select2-container {
    border-color: $red-color !important;

    .select2-selection--single .select2-selection__rendered {
      color: $grey-color-light;
    }
  }
}
</style>
