<template lang="pug">
.page
  navbar

  section.first
    .animation.left.animated
      h1 {{ $t('.firstSection.title.firstPart') }}
        span {{ $t('.firstSection.title.secondPart') }}

      p.font-normal.text-justify
        | {{ $t('.firstSection.content') }}

    .animation.right.animated
      .card
        .content
          h3 {{ $t('.firstSection.right.title') }}

          p {{ $t('.firstSection.right.subtitle') }}

          form(ref="form")
            .first-step(
              ref="step1"
              :class="step === 1 ? 'active' : 'hidden'"
            )
              input-field(
                name="text_1611927772443",
                :error="errors.text_1611927772443"
                :label="$t('.label.companyName')",
                :placeholder="$t('.placeholder.companyName')"
                required
              )

              input-field(
                name="cnpj_1611927954571",
                :error="errors.cnpj_1611927954571"
                :label="$t('.label.cnpj')"
                :placeholder="$t('.placeholder.cnpj')"
                mask="##.###.###/####-##"
                required
              )

              input-field(
                name="text_1675866789806",
                :error="errors.text_1675866789806"
                :label="$t('.label.creci')",
                :placeholder="$t('.placeholder.creci')"
                required
              )

            .second-step(
              ref="step2"
              :class="step === 2 ? 'active' : 'hidden'"
            )
              input-field(
                type="text"
                name="cep_1611928368238"
                :error="errors.cep_1611928368238"
                :label="$t('.label.zipCode')",
                :placeholder="$t('.placeholder.zipCode')"
                mask="#####-###"
                @input="getAddress"
                v-model="cep"
                required
              )
              input-field(
                type="text"
                name="autofill_1611928843190"
                :error="errors.autofill_1611928843190"
                :label="$t('.label.state')",
                :placeholder="$t('.placeholder.state')"
                v-model="state"
                required
              )
              input-field(
                type="text"
                name="autofill_1611928842753"
                :error="errors.autofill_1611928842753"
                :label="$t('.label.city')",
                :placeholder="$t('.placeholder.city')"
                v-model="city"
                required
              )
              input-field(
                type="text"
                name="autofill_1675866924393"
                :error="errors.autofill_1675866924393"
                :label="$t('.label.neighborhood')",
                :placeholder="$t('.placeholder.neighborhood')"
                v-model="neighborhood"
                required
              )
              input-field.address-field(
                type="text"
                name="autofill_1611928838744"
                :error="errors.autofill_1611928838744"
                :label="$t('.label.address')",
                :placeholder="$t('.placeholder.address')"
                v-model="street"
                required
              )
              input-field(
                type="number"
                name="autofill_1675866899903"
                :error="errors.autofill_1675866899903"
                :label="$t('.label.number')",
                :placeholder="$t('.placeholder.number')"
                v-model="number"
                required
              )
              input-field(
                type="text"
                name="autofill_1675866909268"
                :error="errors.autofill_1675866909268"
                :label="$t('.label.complement')",
                :placeholder="$t('.placeholder.complement')"
              )

            .third-step(
              ref="step3"
              :class="step === 3 ? 'active' : 'hidden'"
            )
              input-field(
                name="text_1611928641021",
                :error="errors.text_1611928641021"
                :label="$t('.label.bankCode')",
                :placeholder="$t('.placeholder.bankCode')"
                required
              )
              input-field(
                name="text_1611928715154",
                :error="errors.text_1611928715154"
                :label="$t('.label.agency')",
                :placeholder="$t('.placeholder.agency')"
                required
              )
              input-field(
                name="text_1611928781191",
                :error="errors.text_1611928781191"
                :label="$t('.label.account')",
                :placeholder="$t('.placeholder.account')"
                required
              )
              input-field(
                name="text_1675866239823",
                :error="errors.text_1675866239823"
                :label="$t('.label.pix')",
                :placeholder="$t('.placeholder.pix')"
              )

            .fourth-step(
              ref="step4"
              :class="step === 4 ? 'active' : 'hidden'"
            )
              input-field(
                name="text_1612270603917",
                :error="errors.text_1612270603917"
                :label="$t('.label.representativeLegal')",
                :placeholder="$t('.placeholder.representativeLegal')"
                required
              )
              input-field(
                name="cpf_1611929297732",
                :error="errors.cpf_1611929297732"
                :label="$t('.label.representativeLegalCpf')",
                :placeholder="$t('.placeholder.representativeLegalCpf')"
                mask="###.###.###-##"
                required
              )
              email-field(
                name="email_1611929541234",
                :error="errors.email_1611929541234"
                :label="$t('.label.representativeLegalEmail')",
                :placeholder="$t('.placeholder.representativeLegalEmail')"
                required
              )
              input-field(
                name="text_1611930390120",
                :error="errors.text_1611930390120"
                :label="$t('.label.signatureDate')",
                :placeholder="$t('.placeholder.signatureDate')"
                required
              )

            .fifth-step(
              ref="step5"
              :class="step === 5 ? 'active' : 'hidden'"
            )
              file-field(
                name="file_1612203887964"
                accept="application/pdf"
                :error="errors.file_1612203887964"
                :label="$t('.label.socialContract')"
                destroyable
              )
              file-field(
                name="file_1612203990816"
                accept="application/pdf"
                :error="errors.file_1612203990816"
                :label="$t('.label.lastChange')"
                destroyable
              )

        .buttons.mt-4
          button.button-primary-outline.button.mr-4(
            @click="previousStep"
            :disabled="step === 1"
          )
            | {{ $t('.firstSection.right.buttons.previous') }}
          button.button-primary(
            @click="nextStep"
            v-if="step !== 5"
          )
            | {{ $t('.firstSection.right.buttons.next') }}
          button.button-primary(
            type="submit"
            @click="submit"
            v-else
          )
            | {{ $t('.firstSection.right.buttons.submit') }}

  section.second

  Footer

  partnership-term-modal(
    @close="showSuccessModal = false"
    :show="showSuccessModal"
  )
</template>

<script>
import Navbar from './components/navbar.vue'
import Footer from './components/footer.vue'
import PartnershipTermModal from './components/partnership-term-modal.vue'

import objects from 'lib/objects'
import strings from 'lib/strings'
import cep from 'cep-promise'

export default {
  components: { Navbar, Footer, PartnershipTermModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "staticPages.partnershipTerm",

      // Steps
      step: 1,

      errors: {},

      showSuccessModal: false,

      state: null,
      neighborhood: null,
      city: null,
      street: null,
      cep: null
    }
  },

  mounted() {
    window.addEventListener("scroll", this.animateElements);
  },

  methods: {
    animateElements() {
      const elements = document.querySelectorAll(".animation");

      elements.forEach((element) => {
        const windowHeight = window.innerHeight
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 50

        if (elementTop < (windowHeight - elementVisible) && !element.classList.contains('animated')) {
          element.classList.add("animated");
        }
      })
    },

    getAddress(value) {
      let cepValue = value.target.value.replace('-', '')

      if (cepValue.length < 8) return

      cep(cepValue).then((result) => {
        this.state = result.state
        this.neighborhood = result.neighborhood
        this.city = result.city
        this.street = result.street
        this.cep = `${result.cep.slice(0, 5)}-${result.cep.slice(5)}`
      })
    },

    mediaPath(file) {
      return require(`@/assets/images/for_tenants/${file}`)
    },

    validateFields(ref, callback) {
      const elements = ref.querySelectorAll('input, select, textarea')

      for (const el of elements) {
        if (!el.checkValidity()) {
          el.focus()
          el.reportValidity()

          return
        }
      }

      callback()
    },

    nextStep() {
      const ref = this.$refs[`step${this.step}`]

      this.validateFields(ref, () => { this.step += 1 })
    },

    previousStep() {
      this.step -= 1
    },

    submit() {
      this.validateFields(this.$refs.form, () => {
        const url = '/api/v1/partnership-terms'
        const formData = new FormData(this.$refs.form)

        if (formData.get('file_1612203887964').size == 0) {
          formData.set('file_1612203887964', '')
        }

        if (formData.get('file_1612203990816').size == 0) {
          formData.set('file_1612203990816', '')
        }

        this.$http.post(url, formData)
          .then(() => {
            this.showSuccessModal = true
          })
          .catch(({ response }) => {
            const data = objects.camelize(response.data)
            const fields = data?.flowResult?.answers

            fields?.forEach(({ name, errors }) => {
              this.errors[strings.snakeize(name)] = errors.join(', ')
            })

            this.step = 1

            this.$notifications.error(this.$t(".notifications.submit.failure"))
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/static_pages/section_padding";

  $primary-high-light-color: #EEE5FB;
  $primary-light-color: #A575EC;
  $primary-color: #8E4EC5;
  $primary-dark-color: #452763;
  $yellow-color: #FFE040;

  .animation {
    opacity: 0;

    &.animated {
      opacity: 1;
      animation-timing-function: ease-out;
      animation-duration: 1.5s;

      &.left {
        animation-name: moveInLeft;
      }

      &.right {
        animation-name: moveInRight;
      }

      &.bottom {
        animation-name: moveInBottom;
      }

      &.top {
        animation-name: moveInTop;
      }
    }
  }

  .card {
    background-color: $white-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 55rem;

    padding: 3rem 3rem 1rem 3rem;
    border-radius: 0.5rem;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;


      .second-step {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;

        .address-field {
          grid-column: 1 / 3;
        }
      }
    }

    h3 {
      text-align: center;
    }

    p {
      font-size: 1.5rem;
      text-align: center;
      color: $second-color;
      margin-bottom: 1.5rem !important;
    }

    .buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  section {
    width: 100%;
    padding: 12rem 6rem;
  }

  section.second {
    height: 3vh;
    background-color: $primary-color;
    padding: 0;
  }

  p {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.7rem;

    &.subtitle {
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  h2 {
    font-size: 4.8rem;
  }

  h1, h2 {
    font-weight: 800;
  }

  h3 {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    font-family: 'Open Sans';
    color: $primary-color;
  }

  h1, h2, p {
    margin: 0;
    font-family: 'Open Sans';
    color: $primary-dark-color;

    span {
      color: $primary-color;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
    height: auto;

    button, .button {
      margin: 0;
      padding: 0 2.4rem;
      height: 4.8rem;
      line-height: 4.8rem;

      text-transform: none;
      font-size: 1.6rem;
      font-weight: 700;

      &.button-yellow {
        color: $primary-dark-color;
        border-color: $yellow-color;
        background-color: $yellow-color;

        &:hover, &:focus {
          color: $primary-dark-color;
          border-color: darken($yellow-color, 15%);
          background-color: darken($yellow-color, 15%);
        }
      }

      &.button-primary-dark-outline {
        color: $primary-dark-color;
        border-color: $primary-dark-color;
        background-color: inherit;

        &:hover, &:focus {
          color: $white-color;
          border-color: $primary-dark-color;
          background-color: $primary-dark-color;
        }
      }
    }
  }

  section.first {
    background-image: url('~src/assets/images/for_tenants/city.svg');
    background-size: auto 95%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: $primary-high-light-color;

    border-top: 6rem solid $primary-high-light-color;
    padding: 10rem 6rem;
    height: 97vh;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    align-items: center;

    h1 {
      font-size: 5.6rem;
      line-height: 6.7rem;

      margin-bottom: 2.4rem;
    }

    p {
      margin-bottom: 4rem;
    }
  }

  @media only screen and (max-width: 930px) {
    section {
      padding: 6.4rem 2rem;
    }

    p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
    }

    h2 {
      font-size: 3.6rem;
    }

    h1, h2, h3 {
      font-weight: 700;
    }

    section.first {
      display: block;
      padding: 6rem 2rem 6rem 2rem;

      h1 {
        margin-bottom: 2rem;

        font-size: 4rem;
        line-height: 4.8rem;
      }

      p {
        margin-bottom: 2.4rem;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    section.first {
      background-size: auto 70%;
      background-position: 15% bottom;
      height: auto;
    }
  }
</style>